@import '../../../base/assets/scss/_base/config';
@import '../../../base/assets/scss/_common/sidebar-news-feeds';

.m-mp{
  text-align: left;
  padding: 20px 10px;
  background-color: #fff;

  &__inner{
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scrollbar-width: none; // Firefox Hide scrollbar
    -ms-overflow-style: none; // IE Hide scrollbar

    &::-webkit-scrollbar {
      // Webkit Hide scrollbar
      display: none;
    }
  }

  &__provider{
    display: flex;
    padding-bottom: 8px;
    &--name{
      margin: 0;
      font-size: 11px;
      font-weight: 500;
      color: $backwater;
    }
    &--img{
      &--container{
        padding-right: 5px;
        height: 16px;
      }
    }
  }
  &__heading{
    font-size: 18px;
    margin-bottom: 14px;
    line-height: 22px;
    margin-top: 0;
    text-align: left;
    color: $batcave;
    font-family: var(--font-family-heading);
  }

  &__item{
    display: flex;
    flex-direction: column-reverse;
    flex: 1 0 40vw;
    padding: 5px 8px 5px 0;
  }

  &__title{
    margin: 0px;
    text-align: left;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    font-family: var(--font-family-heading);
    color: $batcave;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  &__link{
    text-decoration: none;
    display: block;
    text-align: left;
    width: 100%;
    margin-bottom: 16px;

    &--title{
      width: 100%;
    }

    &--img{
      width: 100%;
      margin-bottom: 12px;
    }
  }

  &__img{
    &--container{
      width: 100%;
      height: 133px;
      border-radius: 12px;
      overflow: hidden;
      position: relative;
    }

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  .taboola-wrapper{
    padding: 5px 8px 5px 0px;
  }

  @media screen and (min-width: 481px) {
    .m-mp{
      &__inner{
        display: block;
      }
      &__item{
        flex-direction: row;
        width: 100%;
        padding: 0;
      }
      &__link{
        &--title{
          flex: 1 1;
          padding-right: 16px;
        }

        &--img{
          width: 80px;
          flex: 0 0 80px;
          margin-bottom: 16px;
        }
      }
      &__img{
        &--container{
          height: 0;
          padding-top: 100%;
          border-radius: 8px;
        }
      }
    }
    .taboola-wrapper{
      padding: 0;
      margin-bottom: 16px;
    }
  }
}
